body {
  background: url("./images/bg.png");
  color: #42290c;
}
.clear {
  clear: both;
}
.header-image {
  padding: 10px 0;
  text-align: center;
}
.controls {
  width: 500px;
  margin-left: 220px;
}
.controls form#controls {
  margin: 0;
}
.controls form#controls .state-selector {
  width: 75px;
}
.controls form#controls .search {
  float: right;
  width: 100px;
}
table {
  margin: 0 auto 25px auto;
}
table td.name {
  width: 220px;
  text-align: right;
  padding-right: 10px;
  font-weight: bold;
  color: #775228;
  text-transform: uppercase;
}
table td.bar {
  text-transform: uppercase;
  font-weight: bold;
  color: #775228;
  width: 730px;
  position: relative;
}
table.year-round {
  margin: 25px auto 25px auto;
}
table.year-round td.bar {
  width: 500px;
  padding: 0 230px 0 0;
}
img.current-line {
  height: 100%;
  width: 1px;
  position: absolute;
  z-index: 100;
}
img.month-bars {
  position: absolute;
  opacity: 0.25;
  filter: alpha(opacity=25);
  top: 4px;
  left: 0;
}
.season-bar {
  width: 500px;
  margin: 5px 0;
  overflow: hidden;
  position: relative;
  height: 20px;
  background: url("./images/bg-med.png");
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.season-bar-inner {
  width: 0%;
  height: 100%;
  float: left;
  -webkit-box-shadow: inset 0 6px 4px -4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 6px 4px -4px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 6px 4px -4px rgba(0, 0, 0, 0.1);
  background-color: #b4c48a;
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  -moz-background-size: 40px 40px;
  -o-background-size: 40px 40px;
  background-size: 40px 40px;
}
.season-bar-inner.in-season {
  background-color: #9dc733;
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.footer-container {
  padding: 0 0 25px 0;
}
.footer-container .footer {
  width: 500px;
  margin: 0 auto;
}
.footer-container .footer #headshot {
  background: url("./images/iceland_avi.png");
  background-size: 160px 160px;
  height: 160px;
  width: 160px;
  margin: 50px auto;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}
